import styles from './index.module.scss';
import {useInView} from "react-intersection-observer";
import {CaretRightOutlined, CheckCircleFilled, MinusCircleOutlined, PlusCircleOutlined} from "@ant-design/icons";
import ReactDOM from 'react-dom';
import React, {useEffect, useState} from "react";
import {Button, Collapse, message} from "antd";
import FingerprintJS from '@fingerprintjs/fingerprintjs';

const { Panel } = Collapse;

const ExampleWidget = () => {
  const [ref, inView] = useInView({
    triggerOnce: true, // 仅触发一次
  });

  return (
    <div ref={ref} className={`${styles.example_wrapper}`}>
      <div className={`${styles.example_item} ${inView && 'gradient-an'}`}>
        <div className={styles.example_img1}>

        </div>
        <div className={styles.title}>Easy Operation</div>
        <div className={styles.sub_title}>No complex equipment needed, mine easily with your phone</div>
        <div className={styles.content}>
          The Tiger Network has implemented a user-friendly mobile mining mechanism, allowing users to mine without complex equipment. This is achieved by optimizing the mining application to run on standard smartphones. The mining process is automated and simplified, enabling users to start mining with just a few simple steps. This method reduces the technical and hardware barriers of traditional mining, making it easier for a wider range of users to participate and benefit. As a result, the Tiger Network expands its user base, making digital currency mining more widespread and accessible.
        </div>
      </div>
      <div className={`${styles.example_item} ${inView && 'gradient-an'}`}>
        <div className={styles.example_img2}></div>
        <div className={styles.title}>Fair Opportunity</div>
        <div className={styles.sub_title}>Everyone has the chance to participate and benefit</div>
        <div className={styles.content}>
          The Tiger Network has made it easier to participate in cryptocurrency mining. Users don't need high-performance computing hardware; a regular smartphone is sufficient. Additionally, the Tiger Network may have implemented an incentive mechanism to encourage user participation and contribution to the network, such as earning tokens through community engagement or completing specific tasks. This way, everyone has the opportunity to join in the mining activities and benefit, regardless of their economic status.
        </div>
      </div>
      <div className={`${styles.example_item} ${inView && 'gradient-an'}`}>
        <div className={styles.example_img3}></div>
        <div className={styles.title}>Cultural Integration</div>
        <div className={styles.sub_title}>The symbolic significance of the cow combined with digital currency.</div>
        <div className={styles.content}>
          The Tiger Network achieves cultural integration by blending the symbolic significance of cows with digital currency. In Indian culture, the cow symbolizes prosperity, tranquility, and vitality. The Tiger Network incorporates this cultural symbol into the concept of digital currency, creating a new form of money. This approach not only respects traditional culture but also adds new cultural dimensions and meanings to digital currency. Thus, the Tiger Network aims to connect more deeply with users, especially in a culturally rich country like India.
        </div>
      </div>
    </div>
  );
};

const PublicizeWidget = ({ children, textAlign = 'left', imgSty }) => {
  const [ref, inView] = useInView({
    triggerOnce: true, // 仅触发一次
  });

  return (
    <div ref={ref} className={styles.publicize_wrapper}>
      {
        textAlign === 'right' && (
          <div className={`${styles.right_wrapper} ${inView && 'left-enter-an'}`}>
            <div className={imgSty}></div>
          </div>
        )
      }
      <div className={`${styles.left_wrapper} ${inView ? textAlign === 'left' ? 'left-enter-an' : 'right-enter-an' : ''}`}>
        {/* eslint-disable-next-line no-undef */}
        { React.Children.map(children, child => child) }
      </div>
      {
        textAlign === 'left' && (
          <div className={`${styles.right_wrapper} ${inView && 'right-enter-an'}`}>
            <div className={imgSty}></div>
          </div>
        )
      }
    </div>
  );
};

const ContactWidget = () => {

  const [ref, inView] = useInView({
    triggerOnce: true, // 仅触发一次
  });

  const jumpLink = (url) => {
    if (url === '') {
      message.warn('Stay tuned!');
      return;
    }
    window.open(url, '_blank');
  };

  return (
    <div ref={ref} className={styles.contact_wrapper}>
      <div onClick={() => jumpLink('https://wa.me/qr/6CSPPN5RSTQVK1')} className={`${styles.left_wrapper} ${inView && 'left-enter-an'}`}>
        <div className={styles.bg_wrapper}></div>
        <div className={styles.title}>Customer service center</div>
        <div className={styles.sub_title}>Be a part of our community and join now</div>
      </div>
      <div className={`${styles.right_wrapper} ${inView && 'right-enter-an'}`}>
        <div className={styles.community_box}>
          <div className={styles.title}>Community center</div>
          <div className={styles.connect_app_wrapper}>
            <div onClick={() => jumpLink('')} className={styles.connect_app_item}>
              <div className={styles.icon}>
                <div className={styles.discord_icon}></div>
              </div>
              <div className={styles.name}>Discord</div>
            </div>
            <div onClick={() => jumpLink('https://x.com/Jack1142587?t=1HAFyuUaND8IaqZxxDuibQ&s=09')} className={styles.connect_app_item}>
              <div className={styles.icon}>
                <div className={styles.twitter_icon}></div>
              </div>
              <div className={styles.name}>Twitter</div>
            </div>
            <div onClick={() => jumpLink('https://t.me/aicowcoin')} className={styles.connect_app_item}>
              <div className={styles.icon}>
                <div className={styles.telegram_icon}></div>
              </div>
              <div className={styles.name}>Telegram</div>
            </div>
            <div onClick={() => jumpLink('https://www.facebook.com/profile.php?id=61554309178277&mibextid=kFxxJD')} className={styles.connect_app_item}>
              <div className={styles.icon}>
                <div className={styles.facebook_icon}></div>
              </div>
              <div className={styles.name}>Facebook</div>
            </div>
          </div>
        </div>
        <div onClick={() => jumpLink('https://wa.me/qr/6CSPPN5RSTQVK1')} className={styles.join_box}>
          <div className={styles.join_bg}></div>
          <div className={styles.title}>Join us on</div>
          <div className={styles.sub_title}>Be a part of our community and join now</div>
        </div>
      </div>
    </div>
  );
};

const HelpWidget = () => {
  const [ref, inView] = useInView({
    triggerOnce: true, // 仅触发一次
  });
  const [collapseActiveKey, setCollapseActiveKey] = useState([]);

  const onCollapseChange = (keys) => {
    if (keys.length > 0) {
      setCollapseActiveKey(keys[keys.length - 1]);
    } else {
      setCollapseActiveKey([]);
    }
  };
  return (
    <div ref={ref} className={styles.help_wrapper}>
      <div className={`${styles.title_wrapper} ${inView && 'gradient-an'}`}>
        <span>Help guide</span>
      </div>
      <div className={styles.content_wrapper}>
        <div className={`${styles.left_wrapper} ${inView && 'left-enter-an'}`}>
          <Collapse
            bordered={false}
            expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
            className={"site-collapse-custom-collapse"}
            expandIconPosition={"end"}
            activeKey={collapseActiveKey}
            onChange={onCollapseChange}
            ghost={true}
          >

            <Panel showArrow={false} extra={collapseActiveKey.includes('1') ? <MinusCircleOutlined /> :  <PlusCircleOutlined />} header={"What kind of software is Cow Coin APP ?"} key={"1"} className={"site-collapse-custom-panel"}>
              <p>It is a cutting-edge technology project based on blockchain technology and artificial intelligence technology, aiming to distribute blockchain points Cow Coin for free. Achieve universal basic income ( UBI ) for global residents , and establish a fair digital identity ( Cow Coin ID ) and digital currency entity system ( Cow Coin APP ) for global residents, promote the rapid development of the global digital economy, and allow global users to enjoy artificial intelligence The huge dividends brought by new developments in the new era. Cow Coin Smart AI will develop a series of smart AI assistant tools based on artificial intelligence algorithms in multiple fields such as text generation, image generation, voice generation, and video generation. The high - quality content they continuously output in place of manual work will bring huge benefits to the platform. business value, as well as the platform blockchain points held by global residents.</p>
            </Panel>
            <Panel showArrow={false} extra={collapseActiveKey.includes('2') ? <MinusCircleOutlined /> :  <PlusCircleOutlined />} header={"What is cow coin ? What is the use?"} key={"2"} className={"site-collapse-custom-panel"}>
              <p>Cow Coin is based on blockchain technology to issue global blockchain points. The total issuance of Cow Coin is 5 billion, which is composed of POS mining income and POW staking income . The total issuance is 5 billion, of which 100% is free for global users. Obtain.<br /><br />Cow Coin is a universal currency issued by the digital currency entity system ( Cow Coin APP ). Later, with the establishment and improvement of the Cow Coin brand, the value of the Cow Coin points will continue to increase.<br /><br />In the current early and rapid development period, the acquisition rate of cattle coins will remain at the highest level. As the Cow Coin brand continues to develop, the acquisition rate of Cow Coin will continue to decrease, and the number of points earned at one time will also gradually decrease. The sooner you join Cow Coin currency ecosystem, the easier it will be to obtain more Cow Coins .</p>
            </Panel>
            <Panel showArrow={false} extra={collapseActiveKey.includes('3') ? <MinusCircleOutlined /> :  <PlusCircleOutlined />} header={"Why do some mobile phones prompt that Cow Coin APP is a virus risk software?"} key={"3"} className={"site-collapse-custom-panel"}>
              <p>First of all, let me state that the Cow Coin platform is operated cleanly and legally. The software does not contain any viruses or malicious code, and it will not steal or leak any user information! Please feel free to download and use it.<br /><br />" virus risk software " prompt may be due to the fact that our software uses some special technologies, which are similar to the security mechanism of mobile phone systems. Our software has absolutely no malicious behavior.<br /><br />We attach great importance to the privacy and security of our users, and the Cow Coin platform assures all members that Cow Coin The APP has absolutely no viruses or risks. Our goal and purpose are always to solve users\' practical problems and continuously improve user experience. Members please choose to ignore any unfounded and malicious slander when downloading and installing, and download and use it with confidence!</p>
            </Panel>
          </Collapse>
        </div>
        <div className={`${styles.right_wrapper} ${inView && 'right-enter-an'}`}>
          <div className={styles.help_img}></div>
        </div>
      </div>
    </div>
  );
};

function Home() {

  // http://aicow.io/downloads/block_ai.apk

  return (
    <div className={styles.home_page}>
      <div className={`${styles.introduction_wrapper} gradient-an`}>
        <div className={styles.content_wrapper}>
          <div className={styles.title}>An innovative social networking platform</div>
          <div className={styles.mobile_img_wrapper}></div>
          {/* <div className={styles.download_wrapper}>*/}
          {/*  <a href={'//cownetwork.io/downloads/Cow_Network.apk'} target={'_blank'}><span className={styles.android_download_icon}></span></a>*/}
          {/* </div>*/}
          <div className={styles.hint_text}>
            Tiger Network is an innovative social networking platform designed to reward users in a unique and interactive way. <br/>On this platform, users can accumulate points through daily check-ins, as well as earn additional rewards by inviting friends to join. This new type of social network not only fosters interaction among people but also enhances the fun and value of user participation through a points reward system.
          </div>
        </div>
        <div className={styles.right_wrapper}>
          <div className={styles.img_wrapper}></div>
        </div>

      </div>
      {/* <ExampleWidget />*/}
      {/* <div className={styles.video_wrapper}>*/}

      {/* </div>*/}
      <PublicizeWidget textAlign={'left'} imgSty={styles.app_img_style}>
        <div className={styles.title}>Tiger Network's core features include:</div>
        <div className={styles.ordinance_item}>
          <CheckCircleFilled className={styles.success_icon} />
          <span className={styles.text}>Users can earn points by logging in and checking in daily.</span>
        </div>
        <div className={styles.ordinance_item}>
          <CheckCircleFilled className={styles.success_icon} />
          <span className={styles.text}>Tiger Network encourages users to invite their friends to join the platform.</span>
        </div>
        <div className={styles.ordinance_item}>
          <CheckCircleFilled className={styles.success_icon} />
          <span className={styles.text}>We ensure a smooth user experience while minimizing battery consumptionand carbon footprint.</span>
        </div>
        <div className={styles.ordinance_item}>
          <CheckCircleFilled className={styles.success_icon} />
          <span className={styles.text}>User privacy and data security are our top priorities.</span>
        </div>
        {/* <div className={styles.sub_title}>Mining Cow Coin is free</div>*/}
        {/* <div className={styles.content_text}>All you need is an invitation from an existing trusted member of the network. If you have an invitation you can download the mobile app below.</div>*/}
        {/* <div className={styles.sub_title}>Mining is so easy, anyone with a phone can do it！</div>*/}
        {/* <div className={styles.action_link}>*/}
        {/*  <div className={styles.icon}><div className={styles.download_icon}></div></div>*/}
        {/*  <span className={styles.text}>Download CowNetwork Now!</span>*/}
        {/* </div>*/}
      </PublicizeWidget>

      <PublicizeWidget textAlign={'right'} imgSty={styles.invite_img_style}>
        <div className={styles.title}>Invite your friends and create your micro-community</div>
        <div className={styles.content_text}>
          Tiger Network is not just a social networking platform; it is a community that motivates user participation and contribution through a points reward mechanism. Here, everyone's activities and contributions are recognized and rewarded, creating a positive and vibrant social environment. <br/> With the development of the digital era, Tiger Network has unveiled new possibilities for social networking, enhancing user engagement and satisfaction through its reward system, and fostering the healthy growth of the community. Whether you are an active user of social media or someone seeking new experiences, Tiger Network welcomes you to join and create more value and fun together.
        </div>
        {/* <div className={styles.action_link}>*/}
        {/*  <div className={styles.icon}><div className={styles.study_icon}></div></div>*/}
        {/*  <span className={styles.text}>Learn More</span>*/}
        {/* </div>*/}
      </PublicizeWidget>

      {/* <PublicizeWidget textAlign={'left'} imgSty={styles.organization_img_style}>*/}
      {/*  <div className={styles.title}>Decentralized Autonomous Organization</div>*/}
      {/*  <div className={styles.sub_title}>The power to decide is in your hands.</div>*/}
      {/*  <div className={styles.content_text}>*/}
      {/*    Everything that we develop at Tiger Network is open source & controlled by the Decentralized Autonomous Organization (DAO).*/}
      {/*  </div>*/}
      {/*  <div className={styles.action_link}>*/}
      {/*    <div className={styles.icon}><div className={styles.study_icon}></div></div>*/}
      {/*    <span className={styles.text}>Learn More</span>*/}
      {/*  </div>*/}
      {/* </PublicizeWidget>*/}

      {/* <ContactWidget />*/}

      {/* <HelpWidget />*/}
    </div>
  );
}

export default Home;
